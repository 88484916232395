import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ClientMessage",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiButton",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiPlanOption",
      "ComponentUiSection",
      "ComponentUiSectionWithButtons",
      "ComponentUiSectionWithCards",
      "ComponentUiTab",
      "ComponentUiText",
      "Home",
      "Layout",
      "PlanCard",
      "ReactIconsIconlibrary",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ClientMessage",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiButton",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiPlanOption",
      "ComponentUiSection",
      "ComponentUiSectionWithButtons",
      "ComponentUiSectionWithCards",
      "ComponentUiTab",
      "ComponentUiText",
      "Home",
      "Layout",
      "PlanCard",
      "ReactIconsIconlibrary",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const ClientMessageFragmentDoc = gql`
    fragment ClientMessage on ClientMessage {
  name
  email
  phone
  message
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  subtitle_tablet
  subtitle_phone
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const ButtonFragmentDoc = gql`
    fragment Button on ComponentUiButton {
  url
  title
}
    `;
export const SectionWithButtonsFragmentDoc = gql`
    fragment SectionWithButtons on ComponentUiSectionWithButtons {
  title
  subtitle
  qrCode {
    data {
      ...File
    }
  }
  qrCodeBtn {
    data {
      ...File
    }
  }
  button {
    ...Button
  }
}
    `;
export const PlanOptionFragmentDoc = gql`
    fragment PlanOption on ComponentUiPlanOption {
  isEnabled
  option_name
}
    `;
export const PlanCardFragmentDoc = gql`
    fragment PlanCard on PlanCard {
  card_type
  card_subtitle
  plan_name
  plan_option(pagination: {limit: 100}) {
    ...PlanOption
  }
}
    `;
export const SectionWithCardsFragmentDoc = gql`
    fragment SectionWithCards on ComponentUiSectionWithCards {
  title
  subtitle
  subtitle_phone
  subtitle_tablet
  section_card(pagination: {limit: 100}) {
    ...Card
  }
  plan_cards {
    data {
      attributes {
        ...PlanCard
      }
    }
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const TextFragmentDoc = gql`
    fragment Text on ComponentUiText {
  text
}
    `;
export const CreateClientMessageDocument = gql`
    mutation createClientMessage($data: ClientMessageInput!) {
  createClientMessage(data: $data) {
    data {
      attributes {
        name
        email
        phone
        message
      }
    }
  }
}
    `;
export type CreateClientMessageMutationFn = Apollo.MutationFunction<CreateClientMessageMutation, CreateClientMessageMutationVariables>;
export function useCreateClientMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMessageMutation, CreateClientMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMessageMutation, CreateClientMessageMutationVariables>(CreateClientMessageDocument, options);
      }
export type CreateClientMessageMutationHookResult = ReturnType<typeof useCreateClientMessageMutation>;
export type CreateClientMessageMutationResult = Apollo.MutationResult<CreateClientMessageMutation>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
        how_ecademy {
          ...SectionWithCards
        }
        shaping_champs {
          ...SectionWithCards
        }
        your_route {
          ...SectionWithCards
        }
        your_dreams {
          ...Section
        }
        connectSection {
          ...SectionWithButtons
        }
        ignite_passion {
          ...SectionWithCards
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${SectionWithCardsFragmentDoc}
${PlanCardFragmentDoc}
${PlanOptionFragmentDoc}
${SectionWithButtonsFragmentDoc}
${ButtonFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const LayoutDocument = gql`
    query layout {
  layout {
    data {
      attributes {
        headerMenu {
          ...Link
        }
        headerText {
          ...Text
        }
        wreathInnerText {
          ...Text
        }
        footer {
          ...Card
        }
      }
    }
  }
}
    ${LinkFragmentDoc}
${TextFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;