import { ArrowUpOutlined } from "@ant-design/icons"
import { Button } from "antd"
import './ScrollTopButton.less'

export const ScrollTopButton = () => {

  const handleBackToTop = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0;
  }

  return (
    <Button className={'scroll-button'} onClick={handleBackToTop}>
      <ArrowUpOutlined />
    </Button>
  )
}