import { Space } from "antd"
import { FC } from "react"

import { ReactComponent as LogoImage } from "../../assets/icons/Logo.svg"

import "./index.less"

const Logo: FC = () => {
  return (
    <Space direction={"horizontal"} align={"center"}>
      <LogoImage onClick={() => window.location.assign("/")} className={"logo"} />
    </Space>
  )
}

export { Logo }
