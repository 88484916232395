import { Button, Col, Image, Row, Typography } from "antd"
import { CSSProperties, FC, Fragment, useEffect, useState } from "react"

import { ReactComponent as CloseMenuIcon } from "../../../assets/icons/Close.svg"
import { ReactComponent as BurgerMenuIcon } from "../../../assets/icons/Menu.svg"
import laurel from '../../../assets/images/laurel.png'
import { Logo } from "../../logo"
import { HeaderMenu } from "../../menu"
import { useLayoutQuery } from "../../../graphql"

import { useBreakpoint } from "../../../hooks/useBreakpoint"
import "./index.less"
import { useApp } from "../../../components/app"

const Header: FC<{ renderMenu?: FC }> = ({ renderMenu: HorizontalMenu = HeaderMenu }) => {
  const [width, setWidth] = useState(window.visualViewport?.width)
  const [menuOpen, setMenuOpen] = useState(false)
  const [scrollY, setScrollY] = useState(false)
  const { data } = useLayoutQuery()
  const layoutData = data?.layout?.data?.attributes
  const { xxl } = useBreakpoint()
  const { setIsFormOpen } = useApp().app;
  
  const navigationGap = xxl ? '500px' : '230px'
  
  useEffect(() => {
    const handleResize = () => setWidth(window.visualViewport?.width);
    const handleScroll = () => (window.scrollY > 0 ? setScrollY(true) : setScrollY(false));
    
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [menuOpen]);

  const handleClick = () => {
    setMenuOpen(prevState => !prevState)
  }

  const connectSection = document.getElementById('courses');
  const scrollToSection = () => (
    connectSection?.scrollIntoView({
      block: 'center'
    })
  )
  
  const onItemClick = (item: string) => () => {
    const newItem = item.substring(2)
    
    const section = document.getElementById(newItem)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    if (newItem === 'connect' && section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setIsFormOpen(true)
    }
  };


  return (
    <Fragment>
      <a onClick={scrollToSection}>
        <Row justify={"center"} className={"header-text-wrapper"} >
          <div className={"header-ticker"}>
            {layoutData?.headerText?.map((text, index) => (
              <Typography.Paragraph style={{ margin: 0, padding: 0 }} key={index} className={`header-text ${index === 2 && "text-on-white"}`}>
                {text?.text}
              </Typography.Paragraph>
            ))}
          </div>
        </Row>
      </a>
      <Row 
        className={`header-nav-wrapper ${menuOpen && "burger-menu-background"}`}
        style={{ '--nav-gap': navigationGap } as CSSProperties}
      >
        <Col style={{ cursor: 'pointer' }}>
          <Logo />
        </Col>
        {width && width >= 1440 && (
          <Col className={"header-menu-wrapper"}>
            <HorizontalMenu />
          </Col>
        )}
        {width && width >= 1440 && (
          <Col className={`wreath-wrapper ${scrollY && "small"}`}>
            <Button href={'https://www.allstar1.net/'} target={'_blank'}>
              <Image src={laurel} preview={false}  />
            </Button>
          </Col>
        )}
        {width && width < 1440 && (
          <Col>
            <Button onClick={handleClick}>{menuOpen ? <CloseMenuIcon /> : <BurgerMenuIcon />}</Button>
          </Col>
        )}
      </Row>
      {menuOpen && (
        <Row onClick={handleClick}>
          <Col className={"header-burger-menu-wrapper"}>
            <div className={"burger-menu-list-container"}>
              <ul className={"burger-menu-link-list"}>
                {layoutData?.headerMenu?.map(item => (
                  <li key={item?.url} className={"burger-menu-link"}>
                    <a onClick={onItemClick(item?.url || '')}>{item?.title}</a>
                  </li>
                ))}
              </ul>
              <a className={"wreath-wrapper"} target={'_blank'} href={"https://www.allstar1.net/"} rel={"noreferrer"}>
                <Image src={laurel} className={'burger-menu-image'} />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

export { Header }
