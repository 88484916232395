import { ItemType } from "antd/es/menu/hooks/useItems";
import { FC } from "react";

import { useLocation } from "react-router-dom";

import Navigation from "./Navigation";

import { useLayoutQuery } from "../../graphql";

import "./index.less";
import { useApp } from "../app";

const HeaderMenu: FC = () => {
  const { pathname } = useLocation();
  const { data } = useLayoutQuery();
  const { setIsFormOpen } = useApp().app;

  const menuItems = data?.layout?.data?.attributes?.headerMenu;
  const items = menuItems?.map(item => {
    let itemUrl = item?.url

    if (item?.title === 'Connect') {
      itemUrl = '/#courses'
    }

    return ({
      key: item?.url,
      label: <a href={itemUrl}>{item?.title}</a>,
    }) 
  });

  const onItemClick = (item: ItemType) => {
    if (item?.key === '/#connect') {
      setIsFormOpen(true)
    }
  };

  return (
    <Navigation
      mode={"horizontal"}
      activeKey={pathname}
      defaultActiveFirst
      items={items as ItemType[]}
      onClick={onItemClick}
    />
  );
};

export { HeaderMenu };