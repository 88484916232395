import { Layout as BaseLayout } from "antd"
import { FC, Suspense, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { Footer, Header, Loader } from "."
import { HeaderMenu } from "../menu"

import "./index.less"

const Layout: FC = () => {
  const [contentLoaded, setContentLoaded] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setContentLoaded(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  

    return (
    <BaseLayout style={{ position: "relative" }}>
      <BaseLayout.Header style={{ paddingInline: 0, height: "fit-content" }}>
        <Header renderMenu={HeaderMenu} />
      </BaseLayout.Header>
      <Suspense>
        <Outlet />
      </Suspense>
      {contentLoaded && <BaseLayout.Footer style={{ padding: 0 }}>
        <Footer />
      </BaseLayout.Footer>}
    </BaseLayout>
  )
}

export { Layout }
