import { Col, Image, Row, Typography } from "antd"
import { FC } from "react"
import { ReactComponent as LogoSvg } from '../../../assets/images/EcademyLogo.svg'
import { Logo } from "../../logo"
import { useLayoutQuery } from "../../../graphql"
import "./index.less"
import { Reveal } from "../../../components/reveal"
import { ScrollTopButton } from "./ScrollTopButton"


const Footer: FC = () => {
  const { footer } = useLayoutQuery().data?.layout?.data?.attributes || {}
  return (
    <Row className={"footer-wrapper"}>
      <Col>
        <Reveal 
          opacityStartValue={0.5} 
          transitionStart={300} 
          duration={1} 
          classname={'footer-title-animated-wrapper'}
        >
          <Row justify={"center"}>
            <Typography.Title level={2} className={"footer-title"}>
              {footer?.title}
            </Typography.Title>
          </Row>
        </Reveal>
        <Reveal opacityStartValue={0} delay={2} classname={'image-animated-wrapper'}>
          <Image src={footer?.media?.data?.attributes?.url} className={'footer-image'} preview={false} style={{ objectFit: 'cover' }} />
        </Reveal>
        <Reveal opacityStartValue={0} delay={3} classname={'footer-logo-animated-wrapper'} duration={2}>
          <Row justify={"center"} className={"footer-main-logo-wrapper"}>
            <LogoSvg />
          </Row>
        </Reveal>
        <Row align={"middle"} justify={"center"} className={"footer-rights-wrapper"}>
          <Logo />
          <Typography.Text>{footer?.description}</Typography.Text>
        </Row>
      </Col>
      <ScrollTopButton />
    </Row>
  )
}

export { Footer }
