import { motion, useAnimation, useInView } from "framer-motion"
import { FC, ReactNode, useEffect, useRef } from "react"
import "./Reveal.less"

type Props = {
  children: ReactNode
  transitionStart?: number
  opacityStartValue: number
  animationClass?: string
  classname?: string
  delay?: number
  duration?: number
}

export const Reveal: FC<Props> = ({ 
  children, 
  opacityStartValue, 
  transitionStart, 
  delay, 
  duration, 
  classname, 
  animationClass 
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const isInview = useInView(ref, { once: true, margin: '150px 0px' })
  const mainControls = useAnimation()

  useEffect(() => {
    const startAnimation = async () => {
      if (isInview) {
        await mainControls.start("visible");

        if (animationClass) {
          ref.current?.classList.add(animationClass);
        }
      } 
    };

    startAnimation();
  }, [isInview]);

  return (
    <motion.div
      className={`animated-div ${classname}`}
      ref={ref}
      variants={{
        hidden: { opacity: opacityStartValue, y: transitionStart },
        visible: { opacity: 1, y: 0 },
      }}
      initial={"hidden"}
      animate={mainControls}
      transition={{ duration: duration, delay: delay }}
    >
      {children}
    </motion.div>
  )
}
