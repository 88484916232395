import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { ConfigProvider } from "antd"
import { createContext, memo, useContext, type FC, type PropsWithChildren, useState } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { version } from "../../../package.json"
import introspection from "../../graphql"
import routes from "../../pages"
import theme from "../../themes"

import "./index.less"

type ContextProps = {
  app: {
    version: string
    isFormOpen: boolean
    setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  }
}

const app: ContextProps["app"] = { 
  version,
  isFormOpen: false,
  setIsFormOpen: () => false
}

const Context = createContext({ app })

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  return (
  <Context.Provider value={{ 
    app: {
      ...props.app,
      isFormOpen,
      setIsFormOpen
    }
    }}
   >
    {children}
   </Context.Provider>
  )
}

const useApp: () => ContextProps = () => useContext(Context)

const client = new ApolloClient({
  link: createHttpLink({
    uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
    credentials: "same-origin",
  }),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const router = createBrowserRouter(routes)

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <ContextProvider app={app}>
      <ConfigProvider theme={theme}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </ContextProvider>
  </ApolloProvider>
))

export { useApp }

export default App
