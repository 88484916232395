import "./Loader.less"

import { SpinProps } from "antd"
import { FC, Fragment } from "react"

const Loader: FC<SpinProps> = ({ spinning }) => {
  
  return (
    <Fragment>

        
    </Fragment>
  )

}

export { Loader }
